import React, { useState, useEffect } from 'react';

const ServicesSlider = ({ services }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % services.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [services]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="back-content">
      <h3 className='services-info-title'></h3>
      <div className="slider">
        <div>{services[currentIndex]}</div>
        <div className="dots">
          {services.map((_, i) => (
            <span
              key={i}
              className={`dot ${currentIndex === i ? 'active' : ''}`}
              onClick={() => handleDotClick(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSlider;
