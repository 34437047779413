import React, { useState, useEffect } from 'react';
import BuildingInfo from './BuildingInfo';
import LocationInfo from './LocationInfo';
import ServicesSlider from './ServicesSlider';
import NVCImage from '../../images/NVCImage2.jpeg';
import Lex17Image from '../../images/Lex17Image.png';
import NewmanRealEstate from '../../images/NewmanRealEstate.png';
import NewmanLibrary from '../../images/NewmanLibrary.jpeg';
import StudentCenter from '../../images/StudentCenter.jpeg';
import MarxeSchool from '../../images/TheMarxeSchool.jpeg';
import './Campus.css';

const servicesData = {
  NVC: [
    <div className="service-item">
      <h5><a className='service-title' href='https://sacc.baruch.cuny.edu/'>SACC</a></h5>
      <p className='service-info'>SACC offers academic support services to Baruch students. It provides tutoring, workshops, and other resources to help students excel academically.</p>
      <hr className='above-service-contact'></hr>
      <p >NVC 2-116 | 646-312-4830 | <a href="mailto:sacc@baruch.cuny.edu" className="service-email">sacc@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href='https://studentaffairs.baruch.cuny.edu/starr-career-development-center/'>Starr CDC</a></h5>
      <p className='service-info'>Starr CDC assists students with career development, offering services like resume reviews, career counseling, and internship/job search assistance.</p>
      <hr className='above-service-contact'></hr>
      <p>NVC | 646-312-4670 | <a href="mailto:careerdc@baruch.cuny.edu" className="service-email">careerdc@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href='https://studentaffairs.baruch.cuny.edu/counseling/'>Counseling Center</a></h5>
      <p className="service-info">Counseling Center provides mental health services, including individual counseling, group therapy, and wellness workshops.</p>
      <hr className='above-service-contact'></hr>
      <p>646-312-2155 | <a href="mailto:counseling@baruch.cuny.edu" className="service-email">counseling@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://studentaffairs.baruch.cuny.edu/student-disability-services/">Student Disability Services</a></h5>
      <p className="service-info">Student Disability Services  offers accommodations and support for students with disabilities to ensure equal access to college programs and activities.</p>
      <hr className='above-service-contact'></hr>
      <p>NVC 2-272 | 646-312-4590 | <a href="mailto:disability.services@baruch.cuny.edu" className="service-email">disability.services@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://enrollmentmanagement.baruch.cuny.edu/seek/">SEEK Program</a></h5>
      <p className="service-info">SEEK Program is a higher education opportunity program providing academic and financial support to eligible students.</p> 
      <hr className='above-service-contact'></hr>
      <p>NVC 2-230 | 646-312-4640 | <a href="mailto:seek@baruch.cuny.edu" className="service-email">seek@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://enrollmentmanagement.baruch.cuny.edu/international-student-service-center/">International Student Services Center</a></h5>
      <p className="service-info">International Student Service Center  provides resources and advising for international students on matters like visas, employment, and cultural adjustment.</p>
      <hr className='above-service-contact'></hr>
      <p>NVC | 646-312-2050 | <a href="mailto:issc@baruch.cuny.edu" className="service-email">issc@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://studentaffairs.baruch.cuny.edu/health/">Health Services</a></h5>
      <p className="service-info">Health Services offers a range of health-related services, including medical care, health education, and wellness programs.</p> 
      <hr className='above-service-contact'></hr>
      <p>NVC | 646-312-2040</p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://blogs.baruch.cuny.edu/writingcenter/">Writing Center</a></h5>
      <p className="service-info">Writing Center provides support for students in improving their writing skills, offering one-on-one tutoring sessions and writing workshops.</p> 
      <hr className='above-service-contact'></hr>
      <p>NVC 8-185 | 646-212-4012 | <a href="mailto:writing.center@baruch.cuny.edu" className="service-email">writing.center@baruch.cuny.edu</a></p>
    </div>
  ],
  NewmanLibrary: [
    <div className="service-item">
      <h5><a className='service-title' href="https://library.baruch.cuny.edu/help/">Ask a Librarian</a></h5>
      <p className='service-info'>Baruch's "Ask a Librarian" online service offers 24/7 assistance through chat, phone, email, and appointments, helping users with various library needs. This service aids in locating scholarly articles, journals, books, databases, and more.</p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://bctc.baruch.cuny.edu/students/wireless-mobile-printing/">Printing</a></h5>
      <p className='service-info'>Baruch College allows students to print wirelessly with black & white or color printers through their printing portal. Students typically get 500 credits worth of priting pages per semester. Printers can also be found in other campus buildings.</p>
      <hr className='above-service-contact'></hr>
      <p>2nd Floor</p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://guides.newman.baruch.cuny.edu/databases">Databases A-Z </a></h5>
      <p className='service-info'>The "Databases A-Z" section offers a comprehensive list of databases organized both alphabetically by name and by subject. This resource helps users quickly find and access databases relevant to their research needs.</p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://library.baruch.cuny.edu/students/study-rooms/">Study Rooms</a></h5>
      <p className='service-info'>Baruch and CUNY SPS students can use group study rooms for various purposes, with room keys available for a two-hour loan from the Circulation Desk. Reservations are held for 15 minutes, and fines apply for late key returns. </p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">Technology Loan</a></h5>
      <p className='service-info'>The Technology Loan Service at Baruch offers a variety of technology items for loan. While most items have short loan periods, certain items like Chromebooks and graphing calculators can be borrowed for the entire semester.</p>
      <hr className='above-service-contact'></hr>
      <p>2nd Floor (circulation desk) & 3rd floor (tech loan desk)</p>
   </div>
  ],
  Lex17: [
    <div className="service-item">
      <h5><a className='service-title' href="https://weissman.baruch.cuny.edu/weissman-academic-departments/the-department-of-natural-sciences/">Natural Sciences Department</a></h5>
      <p className='service-info'>The Natural Sciences department at Lex 17 offers a range of courses in biology, chemistry, environmental science, and physics. Students have access to modern laboratories and research facilities to enhance their learning experience.</p>
      <hr className='above-service-contact'></hr>
      <p>17 Lex Room: 920 | 646-660-6200 | <a href="mailto:wsas.natsci@baruch.cuny.edu" className="service-email">wsas.natsci@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://www.alumni.baruch.cuny.edu/bcf/17llc">17 Lex Society</a></h5>
      <p className='service-info'>The 17 Lex Society engages alumni and current students in networking events, professional development workshops, and social activities to foster a strong community and support career advancement.</p>
      <hr className='above-service-contact'></hr>
      <p>17 Lex Room: 920 | 646-660-6200 | <a href="mailto:bcf@baruch.cuny.edu" className="service-email">bcf@baruch.cuny.edu</a></p>
    </div>
  ],
  NewmanRealEstate: [
    <div className="service-item">
      <h5><a className='service-title' href="https://www.newmaninstitute.org/">Real Estate Research Guide</a></h5>
      <p className='service-info'>This guide is designed to help real estate students and practitioners better understand real estate through comprehensive research guidelines, market analysis, and project due diligence.</p>
      <hr className='above-service-contact'></hr>
      <p>Newman Real Estate | 646-660-6950 | <a href="mailto:newman.institute@baruch.cuny.edu" className="service-email">newman.institute@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://guides.newman.baruch.cuny.edu/realestatedatabases">Real Estate Database</a></h5>
      <p className='service-info'>A specialized tool providing access to relevant data, publications, and industry information, essential for conducting in-depth real estate research.</p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="https://zicklin.baruch.cuny.edu/faculty-research/academic-departments/real-estate/zicklin-real-estate-events/">Events</a></h5>
      <p className='service-info'>The institute hosts workshops and seminars on various real estate topics such as market trends, real estate law, property management, and investment strategies, providing valuable insights for students and professionals alike.</p>
      <hr className='above-service-contact'></hr>
    </div>
  ],
  StudentCenter: [
    <div className="service-item">
      <h5><a className='service-title' href="#">Multipurpose Room</a></h5>
      <p className='service-info'>The Aaronson Student Center includes a large multipurpose room designed for various student activities and events, offering a versatile space for gatherings, performances, and workshops.</p>
      <hr className='above-service-contact'></hr>
      <p>Student Center | 646-312-3149 | <a href="mailto:Student.Center@baruch.cuny.edu" className="service-email">Student.Center@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">Lounge Areas and Meeting Spaces</a></h5>
      <p className='service-info'>Comfortable lounge areas and meeting spaces equipped with modern furnishings provide students with inviting environments to relax, study, and collaborate on projects.</p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">Designated Meeting Areas</a></h5>
      <p className='service-info'>The center provides designated meeting areas for over 130 student clubs and organizations at Baruch College, fostering a vibrant campus community and encouraging student involvement.</p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">Social and Study Space</a></h5>
      <p className='service-info'>Envisioned as a dynamic and energizing space, the student center offers areas for socializing and collaborative studying, enhancing the overall student experience on campus.</p>
      <hr className='above-service-contact'></hr>
    </div>
  ],
  MarxeSchool: [
    <div className="service-item">
      <h5><a className='service-title' href="https://baruch-graduate.catalog.cuny.edu/the-campus-student-services-and-student-life/student-services/career-advisement-services">Career Consultations</a></h5>
      <p className='service-info'>Individualized support in career planning, job search strategies, and professional development is provided to help students achieve their career goals.</p>
      <hr className='above-service-contact'></hr>
      <p>Marxe School | 646-660-6798 | <a href="mailto:mspia.careerservices@baruch.cuny.edu" className="service-email">mspia.careerservices@baruch.cuny.edu</a></p>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">Resume and Cover Letter Critiques</a></h5>
      <p className='service-info'>Personalized reviews and updates are offered to help students create effective resumes and cover letters that stand out in the job market.</p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">LinkedIn Profile Analysis</a></h5>
      <p className='service-info'>Advice on enhancing LinkedIn presence for professional networking, ensuring students make the most of their online profiles.</p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">Mock Interviews</a></h5>
      <p className='service-info'>Practice interviews with real-time feedback help students prepare for actual job interviews, increasing their chances of success.</p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">Job and Internship Postings</a></h5>
      <p className='service-info'>Access to a database with current listings of jobs and internships, providing students with opportunities to gain valuable work experience.</p>
      <hr className='above-service-contact'></hr>
   </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">Networking Opportunities</a></h5>
      <p className='service-info'>On-campus recruitment events, job fairs, and career panels connect students with potential employers and industry professionals.</p>
      <hr className='above-service-contact'></hr>
    </div>,
    <div className="service-item">
      <h5><a className='service-title' href="#">MBTI Career Assessment</a></h5>
      <p className='service-info'>Personality type assessments help students explore their strengths and weaknesses in relation to potential career paths, aiding in informed decision-making.</p>
      <hr className='above-service-contact'></hr>
    </div>
  ]
};

const images = {
  NVC: NVCImage,
  NewmanLibrary: NewmanLibrary,
  Lex17: Lex17Image,
  NewmanRealEstate: NewmanRealEstate,
  StudentCenter: StudentCenter,
  MarxeSchool: MarxeSchool
};

const buildingInfo = {
  NVC: {
    title: "Newman Vertical Campus",
    address: "55 Lexington Ave, New York, NY 10010",
    elevator: "Express: 2,5,8,11 | Local: 1-13",
    /*mapProps: {
      mapContainerStyle: { width: '300px', height: '150px' },
      center: { lat: 40.69170146117862, lng: -73.82075512999957 },
      zoom: 21
    }*/
  },
  NewmanLibrary: {
    title: "William & Anita Newman Library",
    address: "151 East 25th Street, New York, NY 10010",
    elevator: "1-5 (library) - 6 (BCTC)"
  },
  Lex17: {
    title: "Lawrence and Eris Field Building",
    address: "17 Lexington Ave, New York, NY 10010",
    elevator: "Local: 1-16"
  },
  NewmanRealEstate: {
    title: "Steven L. Newman Real Estate Institute",
    email: "newman.institute@baruch.cuny.edu",
    address: "137 East 22nd Street, New York, NY 10010"
  },
  StudentCenter: {
    title: "Aaronson Student Center",
    email: "Student.Center@baruch.cuny.edu",
    address: "East 24th Street between Lexington and Third Avenues"
  },
  MarxeSchool: {
    title: "Marxe School of Public and International Affairs",
    email: "angelina.delgado@baruch.cuny.edu",
    address: "135 East 22nd St, New York, NY 10010"
  }
};

const Campus = () => {
  const buildings = ["NVC", "NewmanLibrary", "Lex17", "NewmanRealEstate", "StudentCenter", "MarxeSchool"];

  return (
    <div className="campus-container">
      <h1 className="header">Baruch Campus</h1>

      {buildings.map((building, index) => (
        <div className="campus-main" key={building}>
          {index % 2 === 0 ? (
            <>
              <BuildingInfo title={buildingInfo[building].title} imageSrc={images[building]} />
              <div className="right-content">
                <ServicesSlider services={servicesData[building]} className="service-slider" />
                <LocationInfo {...buildingInfo[building]} />
              </div>
            </>
          ) : (
            <>
              <div className="right-content">
                <ServicesSlider services={servicesData[building]} className="service-slider" />
                <LocationInfo {...buildingInfo[building]} />
              </div>
              <BuildingInfo title={buildingInfo[building].title} imageSrc={images[building]} />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default Campus;
