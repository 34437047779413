import React, { useState, useEffect } from 'react';
import "./HelpBot.css";

function HelpBot() {
  const [messages, setMessages] = useState([
    { role: 'bot', content: 'Hi, how can I help you today?' }
  ]);
  const [input, setInput] = useState('');
  const [currentTypingMessage, setCurrentTypingMessage] = useState('');

  const handleSendMessage = async () => {
    if (input.trim()) {
      const newMessage = { role: 'user', content: input };
      setMessages([...messages, newMessage]);
      setInput('');

      // Send the message to the backend and get the response
      const response = await fetch('http://localhost:5001/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: input })
      });

      const data = await response.json();
      const botMessage = data.reply;

      // Start the typing effect
      startTypingEffect(botMessage);
    }
  };

  const startTypingEffect = (message) => {
    let currentIndex = 0;
    setCurrentTypingMessage('');
    
    const typingInterval = setInterval(() => {
      if (currentIndex < message.length) {
        setCurrentTypingMessage((prev) => prev + message[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'bot', content: message }
        ]);
        setCurrentTypingMessage('');
      }
    }, -50); // Adjust this number to control typing speed
  };

  return (
    <>
    <div className="chat-container">
      <div className="chat-header">
        <h1>BaruchNav Assisstant</h1>
      </div>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${
              message.role === 'user' ? 'chat-message-user' : 'chat-message-bot'
            }`}
          >
            <p>{message.content}</p>
          </div>
        ))}
        {currentTypingMessage && (
          <div className="chat-message chat-message-bot">
            <p>{currentTypingMessage}</p>
          </div>
        )}
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          className="chat-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button className="chat-send-button" onClick={handleSendMessage}>
          Send
        </button>
      </div>
        
     


    </div>
     </>
  );
}

export default HelpBot;
