import React from 'react'

import '../../App.css';

import Hero from "../Hero"
import Cards from '../CardsFolder/Cards';
import Footer from "../Footer"
import Campus from "./HomeScroll/Campus"

function Home () {
    return (
        <>
            <Hero/>
            {/*<Cards/>*/}
            <Campus />
        </>
    )
}

export default Home;