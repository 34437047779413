import React from 'react';

const BuildingInfo = ({ title, imageSrc }) => (
  <div className="building-info">
    <h2>{title}</h2>
    <img src={imageSrc} alt={title} />
  </div>
);

export default BuildingInfo;
