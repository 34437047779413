import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt, faElevator } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const LocationInfo = ({ phone, email, address, elevator, mapProps }) => (
  <div className="location-info">
    {/*<h3 className='location-info-title'>Location Info</h3>*/}
    
    {/*
    <div className="info-item">
      <FontAwesomeIcon icon={faPhone} className="icon-color" />
      <span>{phone}</span>
    </div>
    */}
    
    {email && (
      <div className="info-item">
        <FontAwesomeIcon icon={faEnvelope} className="icon-color" />
        <span>{email}</span>
      </div>
    )}
    <div className="info-item">
      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-color" />
      <span>{address}</span>
    </div>
    {elevator && (
      <div className="info-item">
        <FontAwesomeIcon icon={faElevator} className="icon-color" />
        <span>{elevator}</span>
      </div>
    )}
    
  </div>
);

export default LocationInfo;
