import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '150px'
};

function GoogleMaps({ center, zoom }) {
  // Load the Google Maps API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyACUFN0Oz1FS_IsPWhZ6zs41CWrOEEvrSU"
  });

  // If the API is not yet loaded, you can display a loading spinner or message
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
    >
      {/* Additional map components, markers, etc. */}
    </GoogleMap>
  );
}

export default GoogleMaps;
