import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <p className="footer-text">
          This is not an official website of Baruch College or CUNY; it is a student-made website designed as a guide.
        </p>
        <p className="footer-text">
          Information from the BaruchtNav assistant might be inaccurate, and the assistant may make mistakes. Always check with an advisor and/or an official representative from Baruch of the appropriate department before making any financial or academic decisions.
        </p>
        <p className="footer-text">
          We are continuously updating the model the assistant is being trained on.
        </p>
      </div>
      <div className="footer-bottom">
      </div>
    </div>
  );
}

export default Footer;
